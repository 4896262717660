import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import FlexBox from '../../atoms/FlexBox/FlexBox';
import Text from '../../atoms/Text/Text';
import Title from '../../atoms/Title/Title';
import './_howItWorks.scss';

interface HowItWorksProps extends WithTranslation {}

const HowItWorks: React.FunctionComponent<HowItWorksProps> = ({ t }) => {
  return (
    <FlexBox className="container--responsive">
      <FlexBox
        className="howItWorks_wrapper p-1 mb-3"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Title
          variant="h2"
          size="large"
          className="color--primary text-center mb-1"
        >
          {t('component.howItWorks.title')}
        </Title>
        <FlexBox
          className="howItWorks_container_steps"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <FlexBox
            className="howItWorks_box_step"
            alignItems="center"
            flexDirection="column"
          >
            <FlexBox alignItems="center" flexDirection="column">
              <FlexBox
                className="step background--primary color--secondary border--radius-1 m-1"
                justifyContent="center"
                alignItems="center"
                height="70px"
                width="70px"
              >
                <Text size="large">01.</Text>
              </FlexBox>
              <Text className="color--primary text-center" size="medium">
                {t('component.howItWorks.steps.firstStep')}
              </Text>
            </FlexBox>
            <FlexBox
              className="howItWorks_separation background--primary mt-5 mb-5"
              height="3px"
              width="130px"
            ></FlexBox>
          </FlexBox>
          <FlexBox
            className="howItWorks_box_step"
            alignItems="center"
            flexDirection="column"
          >
            <FlexBox alignItems="center" flexDirection="column">
              <FlexBox
                className="step background--primary color--secondary border--radius-1 m-1"
                justifyContent="center"
                alignItems="center"
                height="70px"
                width="70px"
              >
                <Text size="large">02.</Text>
              </FlexBox>
              <Text className="color--primary text-center" size="medium">
                {t('component.howItWorks.steps.secondStep')}
              </Text>
            </FlexBox>
            <FlexBox
              className="howItWorks_separation background--primary mt-5 mb-5"
              height="3px"
              width="130px"
            ></FlexBox>
          </FlexBox>
          <FlexBox
            className="howItWorks_box_step"
            alignItems="center"
            flexDirection="column"
          >
            <FlexBox alignItems="center" flexDirection="column">
              <FlexBox
                className="step background--primary color--secondary border--radius-1 m-1"
                justifyContent="center"
                alignItems="center"
                height="70px"
                width="70px"
              >
                <Text size="large">03.</Text>
              </FlexBox>
              <Text className="color--primary text-center" size="medium">
                {t('component.howItWorks.steps.thirdStep')}
              </Text>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(HowItWorks);
