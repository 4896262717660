import React from 'react';
import FlexBox from '../../components/atoms/FlexBox';
import HowItWorks from '../../components/molecules/HowItWorks/HowItWorks';
import Monetization from '../../components/molecules/Monetization/Monetization';

import SubHeader from '../../components/molecules/SubHeader/SubHeader';

import Analytics from '../../components/molecules/AnalyticsModule/Analytics';
import Header from '../../components/organisms/Header/Header';
import ConnectYourPosts from '../../components/molecules/ConnectYourPosts/ConnectYourPosts';
import Planification from '../../components/molecules/PlanificationSection/Planification';
import FaqAccordion from '../../components/molecules/FaqAccordion/FaqAccordion';
import Input from '../../components/atoms/Input/Input';

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  return (
    <>
      <FlexBox
        className="text-center"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Header />
        <SubHeader />
        <Analytics />
        <Monetization />
        <Planification />
        <HowItWorks />
        <ConnectYourPosts />
        <FaqAccordion />
        <Input />
      </FlexBox>
    </>
  );
};

export default Home;
