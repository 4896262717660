import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import FlexBox from '../../components/atoms/FlexBox';
import LinkButton from '../../components/atoms/LinkButton';
import Text from '../../components/atoms/Text/Text';
import Title from '../../components/atoms/Title/Title';

import './_pricing.scss';

interface PricingProps extends WithTranslation {}

const Pricing: React.FC<PricingProps> = ({ t }) => {
  return (
    <FlexBox className="container--responsive">
      <FlexBox
        className="pl-1 pr-1 pt-1"
        alignItems="center"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <FlexBox width="100%">
          <Title
            className="pricing_title color--primary text-left"
            size="extralarge"
            variant="h1"
            width="100%"
          >
            {t('component.pricing.titleBestOffers')}{' '}
            <p className="title_for_you heading--extralarge color--rainbow color--primary text-left">
              {t('component.pricing.titleForYou')}
            </p>
          </Title>
        </FlexBox>
        <Text
          className="pricing_description_page color--tertiary mt-1 text-left"
          size="medium"
          lighting="extralight"
          width="100%"
        >
          {t('component.pricing.description')}
        </Text>
        <FlexBox
          className="background--rainbow mt-5 mb-3"
          height="2px"
          width="80%"
        ></FlexBox>
        <FlexBox
          className="pricing_wrapper_pricing"
          height="100%"
          width="100%"
          justifyContent="flex-start"
        >
          <FlexBox
            className="pricing_wrapper_sub_pricing"
            flexDirection="column"
            alignItems="flex-end"
            width="100%"
          >
            <FlexBox
              className="pricing_wrapper_left"
              height="auto"
              width="100%"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <FlexBox
                className="pricing_wrapper_price_details"
                flexDirection="column"
                width="100%"
              >
                <Title
                  className="pricing_price color--primary text-left"
                  size="ultralarge"
                  variant="h2"
                  width="100%"
                >
                  0{t('component.pricing.currency')}
                  <Text size="medium" lighting="extralight">
                    / {t('component.pricing.month')}
                  </Text>
                </Title>
                <Title
                  className="pricing_description_title color--primary text-left"
                  size="medium"
                  variant="h3"
                  width="100%"
                >
                  {t('component.pricing.freeForYou')}
                </Title>
                <Text
                  className="pricing_price_description color--tertiary text-left"
                  size="medium"
                  lighting="extralight"
                >
                  {t('component.pricing.shareOnFb')}
                </Text>

                <FlexBox
                  className="pricing_box_cta pricing_wrapper_left"
                  alignItems="center"
                  width="100%"
                >
                  <LinkButton
                    className="mt-1"
                    variant="primary-effect"
                    href="/signup"
                    size="medium"
                  >
                    <Text className="color--secondary text-left" size="medium">
                      {t('component.pricing.callToActionBtn')}
                    </Text>
                  </LinkButton>
                </FlexBox>
              </FlexBox>
              {/* </FlexBox> */}
            </FlexBox>
            <FlexBox
              className="pricing_illustration"
              width="100%"
              height="300px"
              alignItems="flex-end"
            ></FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(Pricing);
