import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import LinkButton from '../../atoms/LinkButton/LinkButton';

import FlexBox from '../../atoms/FlexBox';

import '../../../sass/layout/_footer.scss';
import Text from '../../atoms/Text/Text';
import Title from '../../atoms/Title/Title';

import '../../../sass/layout/_header.scss';

interface HeaderProps extends WithTranslation {}

const Header: React.FC<HeaderProps> = ({ t }) => {
  return (
    <FlexBox
      className="header_wrapper p-1"
      flexDirection="column"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <FlexBox className="wrapper_header_content" width="100%">
        <Title
          size="ultralarge"
          variant="h1"
          width="90%"
          className="title color--rainbow text-left"
        >
          {t('component.header.title.firstWord')}
          <span className="heading_white_word color--primary text-left">
            {' '}
            {t('component.header.title.secondWord')}
            <span className="dot">.</span>
            hello
          </span>
        </Title>
      </FlexBox>
      <Text
        className="header_description color--tertiary mt-1 text-left"
        size="medium"
        lighting="extralight"
        width="100%"
      >
        {t('component.header.subTitle')}
      </Text>
      <FlexBox
        className="header_cta mt-2"
        justifyContent="flex-start"
        width="100%"
      >
        <LinkButton variant="primary-effect" size="large" href="lol">
          <Text className="color--secondary" size="medium">
            {t('component.header.cta')}
          </Text>
        </LinkButton>
      </FlexBox>
      <FlexBox
        className="background--rainbow mt-3"
        height="2px"
        width="80%"
      ></FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(Header);
